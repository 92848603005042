import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { getPriceStringV2 } from 'client/site-modules/shared/utils/price-utils';
import { TRADE_IN_VALUE } from 'site-modules/shared/constants/appraisal/appraisal';
import { TransactionTypeSelect } from 'site-modules/shared/components/appraisal/transaction-type-select/transaction-type-select';

/**
 * Renders estimated values with type selection.
 */
export class EstimatedValuesTypeSelection extends Component {
  static propTypes = {
    conditionValues: PropTypes.arrayOf(PropTypes.shape({})),
    styleName: PropTypes.string,
    defaultValue: PropTypes.string,
    withCaption: PropTypes.bool,
  };

  static defaultProps = {
    conditionValues: [],
    styleName: '',
    defaultValue: TRADE_IN_VALUE,
    withCaption: true,
  };

  state = {
    selectedType: this.props.defaultValue,
  };

  /**
   * Handles type change.
   * @param {string} value
   */
  handleChange = ({ value }) => {
    this.setState(() => ({ selectedType: value }));
  };

  /**
   * Returns rows with conditions and estimated values.
   * @returns {ReactElement[]}
   */
  renderValues = () => {
    const { selectedType } = this.state;
    const { conditionValues } = this.props;
    return conditionValues.map(condition => {
      const { name } = condition;
      const value = condition[selectedType];

      return (
        <Fragment key={name}>
          <Row className="appraiser-table-row justify-content-between">
            <Col xs={6} className="appraiser-table-row-header py-0_5 text-capitalize">
              {name}
            </Col>
            <Col xs={6} className="text-end py-0_5">
              {getPriceStringV2(value)}
            </Col>
          </Row>
        </Fragment>
      );
    });
  };

  render() {
    const { styleName, withCaption } = this.props;
    const { selectedType } = this.state;

    return (
      <div className="estimated-values-type-selection rounded my-1">
        {withCaption && <p className="text-gray-darker size-16 mb-0_25">{styleName} with no options</p>}
        <TransactionTypeSelect onChange={this.handleChange} selected={selectedType} className="pb-1 mb-0" />
        <div className="text-gray-darker px-0_5">
          <Row className="appraiser-table-row fw-bold justify-content-between">
            <Col xs={6} className="appraiser-table-row-header py-0_5">
              Condition
            </Col>
            <Col xs={6} className="text-end py-0_5">
              Value
            </Col>
          </Row>
          {this.renderValues()}
        </div>
      </div>
    );
  }
}
