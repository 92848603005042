import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from 'reactstrap/lib/Button';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { flow, get } from 'lodash';
import { useToggle } from 'site-modules/shared/hooks/use-toggle';
import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';
import { buildReviewPath, EditorialReviewEntities, EditorialReviewModel } from 'client/data/models/editorial-review';
import { buildVehiclePath, VehicleModel, VehicleEntities } from 'client/data/models/vehicle-v2';
import { getParamsFromVehicle } from 'site-modules/shared/utils/core-page/params';
import { getEditorialIcon } from 'site-modules/shared/utils/core-page/rating-and-ranking';
import { AnimatedCollapse } from 'site-modules/shared/utils/collapse-utils';
import { scrollToTop } from 'client/utils/scroll';
import { SrpCoreHighlights } from 'site-modules/inventory/components/srp-core-highlights/srp-core-highlights';
import { ScorecardEditorialReviewBody } from 'site-modules/shared/components/core-page/scorecard-editorial-review/scorecard-editorial-review-body';
import { OtherYearsForSale } from 'site-modules/inventory/components/other-years-for-sale/other-years-for-sale';
import { ResearchRelatedLinks } from 'site-modules/inventory/components/research-related-links/research-related-links';
import { BuildPriceAd } from 'site-modules/shared/components/native-ad/buildprice-ad/buildprice-ad';
import { IncentivesAd } from 'site-modules/shared/components/native-ad/incentives-ad/incentives-ad';

import './srp-core-content.scss';

export function SrpCoreContentUI({ vehicle, editorialReview, isMobile, pageHeaderText }) {
  const overallRating = get(editorialReview, 'ratings.overall.rating');
  const overallText = get(editorialReview, 'ratings.overall.text');
  const [isFullReviewOpen, setIsFullReviewOpen] = useToggle(false);

  useEffect(() => {
    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
      event_data: {
        action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
        action_name: TrackingConstant.ACTION_SHOW_CONTENT,
        synpartner: TrackingConstant.SYNPARTNER,
        action_cause: TrackingConstant.PAGE_LOAD_CAUSE,
        page_name: 'used_car_inventory_srp',
        page_category: 'car_inventory',
        subaction_name: TrackingConstant.RATING_CATEGORY,
        value: overallRating && getEditorialIcon(overallRating).text,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleFullReviewStateChange() {
    if (!isFullReviewOpen) {
      scrollToTop();
    }
  }

  const toggleFullReviewButton = (
    <Button
      onClick={setIsFullReviewOpen}
      color="blue-50"
      className={classnames('rounded-8 size-16 px-0_75 py-0_25 fw-medium text-capitalize', {
        'w-100': isMobile,
      })}
      data-tracking-id="view_edmunds_review"
      data-tracking-value={`${!isFullReviewOpen ? 'Close' : 'Read'} full review`}
    >
      {`${isFullReviewOpen ? 'Close' : 'Read'} full review`}
    </Button>
  );

  const otherYearsForSale = <OtherYearsForSale vehicle={vehicle} />;
  const researchRelatedLinks = <ResearchRelatedLinks vehicle={vehicle} />;
  const incentiveAd = <IncentivesAd mobile={isMobile} horizontalLayout />;
  const buildPriceAd = <BuildPriceAd mobile={isMobile} wrapperClass="d-flex justify-content-center" />;

  return (
    <div className="srp-core-content mb-1 mb-md-1_5 rounded-12 bg-white p-0_75 p-md-1">
      {pageHeaderText && <h1 className="heading-5 mb-1">{pageHeaderText}</h1>}
      <SrpCoreHighlights editorialReview={editorialReview} toggleButton={toggleFullReviewButton} />
      <AnimatedCollapse isOpen={isFullReviewOpen} onChange={handleFullReviewStateChange}>
        <div className="pt-1_5">
          <ScorecardEditorialReviewBody
            vehicle={vehicle}
            params={getParamsFromVehicle(vehicle)}
            hasRouteYearParam
            isUsed
            showHighlights
            isCoreOnSrp
            toggleButton={toggleFullReviewButton}
            embeddedModules={{
              otherYearsModule: otherYearsForSale,
              researchLinksModule: researchRelatedLinks,
              incentiveAdModule: incentiveAd,
              buildPriceAdModule: buildPriceAd,
            }}
            showSummary={!!overallText}
          />
        </div>
      </AnimatedCollapse>
    </div>
  );
}

SrpCoreContentUI.propTypes = {
  vehicle: VehicleEntities.MakeModelSubmodelYear,
  editorialReview: EditorialReviewEntities.EditorialReview,
  isMobile: PropTypes.bool,
  pageHeaderText: PropTypes.string,
};

SrpCoreContentUI.defaultProps = {
  vehicle: null,
  editorialReview: null,
  isMobile: false,
  pageHeaderText: null,
};

export const SrpCoreContent = flow(
  component =>
    connectToModel(component, {
      editorialReview: bindToPath(
        ({ vehicle }) => !!vehicle && buildReviewPath(getParamsFromVehicle(vehicle)),
        EditorialReviewModel,
        null,
        false
      ),
    }),
  component =>
    connectToModel(component, {
      vehicle: bindToPath(({ vehicleParams }) => vehicleParams && buildVehiclePath(vehicleParams), VehicleModel),
    })
)(SrpCoreContentUI);
