import React, { Fragment, memo } from 'react';
import PropTypes from 'prop-types';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { get, head, isEmpty } from 'lodash';

import { RatingStars } from 'site-modules/shared/components/rating-stars/rating-stars';
import { RatingStarsChart } from 'site-modules/shared/components/rating-stars-chart/rating-stars-chart';
import { convertRatings } from 'site-modules/shared/components/rating-stars-chart/rating-stars-chart-util';
import { numberWithPluralText } from 'site-modules/shared/utils/string';
import {
  WriteReview,
  SeeAllReviewsLink,
  ReportAbuseLink,
} from 'client/site-modules/shared/components/reviews-links/reviews-links';
import { Reviews } from 'client/site-modules/shared/components/reviews/reviews';

/**
 * Combined write/read reviews links.
 * @param {Object} make
 * @param {Object} model
 * @param {Number} year
 * @param {Number} reviewsCount
 * @returns {ReactElement}
 */
export function CombinedReviewsLinks({ vehicle: { make, model }, year, reviewsCount }) {
  const params = {
    makeSlug: make.slug,
    modelSlug: model.slug,
    year,
  };

  const mmsy = {
    make: make.name,
    model: model.name,
    year,
  };

  return (
    <Fragment>
      <WriteReview params={params} mmsy={mmsy} classes="mb-1 d-block text-primary-darker" />
      <SeeAllReviewsLink params={params} reviewsCount={reviewsCount} classes="d-block text-primary-darker" />
    </Fragment>
  );
}

CombinedReviewsLinks.propTypes = {
  vehicle: PropTypes.shape({
    make: PropTypes.shape({}),
    model: PropTypes.shape({}),
  }).isRequired,
  year: PropTypes.number.isRequired,
  reviewsCount: PropTypes.number.isRequired,
};

export const ConsumerReviewsWidget = memo(({ data, vehicle, latestYear, isMobile }) => {
  if (isEmpty(data.reviews)) return null;

  const { averageConsumerRatings, reviews, totalReviewCount, starRatingCount } = data;

  const review = head(reviews);
  const make = get(vehicle, 'make.name');
  const model = get(vehicle, 'model.name');
  const year = Number(get(review, 'modelYear') || get(vehicle, 'year') || get(vehicle, 'modelYear.year') || latestYear);
  const userName = get(review, 'userName') || get(review, 'author.authorName');
  const id = get(review, 'id');
  const title = get(review, 'title');

  const starRatings = convertRatings(starRatingCount);

  return (
    <section className="mt-2_5 mb-2" data-tracking-parent="edm-consumer-reviews-widget">
      <h2 className="heading-3">
        Consumer Reviews for the {make}&nbsp;{model}
      </h2>
      <div className="text-gray-darker">
        Read recent reviews for the {make}&nbsp;{model}
      </div>
      <Row className="mt-1_5 mt-md-1">
        <Col xs={12} md={5}>
          <div className="mb-md-1_5">
            <div className="small text-cool-gray-40 mb-0_5">Overall Consumer Rating</div>

            <span className="heading-4">
              {averageConsumerRatings} <span className="visually-hidden">out of 5 stars</span>
            </span>
            <RatingStars
              className="text-primary-darker m-0 px-0_5"
              rating={averageConsumerRatings}
              hideForScreenReader /* notice we are using hideForScreenReader because we have added 'out of 5 stars' to the "heading-4" span above */
            />
            <span className="text-gray-darker">{numberWithPluralText(totalReviewCount, 'Review', true)}</span>
          </div>
          {!isMobile && <CombinedReviewsLinks vehicle={vehicle} year={year} reviewsCount={totalReviewCount} />}
        </Col>
        <Col xs={12} md={5} className="my-1_5 mt-md-2 mb-md-0">
          <RatingStarsChart
            ratingsTotalCount={totalReviewCount}
            ratings={starRatings}
            backGroundColor="bg-transparent"
          />
        </Col>
      </Row>
      {isMobile && <CombinedReviewsLinks vehicle={vehicle} year={year} reviewsCount={totalReviewCount} />}
      <Reviews mmsy={{ make, model, year }} reviews={reviews} isMobile={isMobile} classes="mt-1_5 mt-md-2_5" />
      <ReportAbuseLink userName={userName} reviewTitle={title} reviewId={id} classes="mt-1" />
    </section>
  );
});

ConsumerReviewsWidget.displayName = 'ConsumerReviewsWidget';

ConsumerReviewsWidget.propTypes = {
  data: PropTypes.shape({
    reviews: PropTypes.arrayOf(PropTypes.shape({})),
    totalReviewCount: PropTypes.number,
    averageConsumerRatings: PropTypes.number,
    starRatingCount: PropTypes.shape({}),
  }),
  vehicle: PropTypes.shape({}),
  latestYear: PropTypes.number,
  isMobile: PropTypes.bool,
};

ConsumerReviewsWidget.defaultProps = {
  data: {},
  vehicle: {},
  latestYear: null,
  isMobile: false,
};
