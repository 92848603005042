import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { InventoryEntities, InventoryModel } from 'client/data/models/inventory';
import { VisitorEntities, VisitorModel } from 'client/data/models/visitor';
import { buildVehiclePath, VehicleEntities, VehicleModel } from 'client/data/models/vehicle-v2';
import { RequestModel } from 'client/data/models/request';
import { get, flow, isNil } from 'lodash';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { URL_CONTEXT_PATH, UrlModel } from 'client/data/models/url';
import { SEOModel } from 'client/data/models/seo';
import { connect } from 'react-redux';
import { Usurp } from 'site-modules/inventory/pages/usurp/usurp';
import { withInventoryListTracking } from 'site-modules/shared/components/inventory-list-tracking/inventory-list-tracking';
import { withListTrackingData } from 'site-modules/shared/components/inventory-list-tracking/list-tracking-data';
import { ExperimentUtil } from 'client/utils/experiment/experiment-util';
import { buildReviewPath, EditorialReviewModel } from 'client/data/models/editorial-review';
import { getParamsFromVehicle } from 'site-modules/shared/utils/core-page/params';
import { isReviewSectionEmpty } from 'site-modules/inventory/utils/is-review-section-empty';
import { isUsedSRPReviewComboPage } from 'site-modules/shared/utils/inventory/srp-review-combo';
import { InventoryDependencyContext } from 'site-modules/shared/components/inventory/inventory-dependency/inventory-dependency-context';
import { usurpDependencyContextValue } from 'site-modules/shared/components/inventory/inventory-dependency/usurp-dependency-context-value';
import { buildSearchIdPath, SemanticSearchModel } from 'client/data/models/semantic-search';
import { transformSuggestedFilters } from 'site-modules/shared/utils/inventory/semantic-search';

const SELECTED_FACETS_FALLBACK = {};

export const UsurpContainerUI = memo(props => {
  const {
    visitorLocation,
    selectedFacets: selectedFacetsProp,
    urlContext,
    inventoryData: inventoryDataProp,
    isAkamaiCachePrefresh,
    isSearchBot,
    semanticSearchSuggestedFilters,
    ...rest
  } = props;
  const [inventoryData, setInventoryData] = useState(inventoryDataProp);
  const selectedFacets = selectedFacetsProp || SELECTED_FACETS_FALLBACK;
  const isCrawler = isAkamaiCachePrefresh || isSearchBot;

  useEffect(() => {
    if (!isNil(inventoryDataProp)) {
      setInventoryData(inventoryDataProp);
    }
  }, [inventoryDataProp]);

  return (
    <InventoryDependencyContext.Provider value={usurpDependencyContextValue}>
      <Usurp
        {...rest}
        inventoryData={inventoryData}
        selectedFacets={selectedFacets}
        visitorLocation={visitorLocation}
        urlContext={urlContext}
        isCrawler={isCrawler}
        modifiedZip={semanticSearchSuggestedFilters?.modifiedZip}
      />
    </InventoryDependencyContext.Provider>
  );
});

UsurpContainerUI.displayName = 'UsurpContainerUI';

UsurpContainerUI.propTypes = {
  inventoryData: PropTypes.shape({
    inventories: PropTypes.shape({
      results: InventoryEntities.InventoriesVin,
      totalNumber: PropTypes.number,
      totalPages: PropTypes.number,
    }),
    facets: InventoryEntities.Facets,
    seoInfo: PropTypes.shape({}),
  }),
  isMobile: PropTypes.bool.isRequired,
  setModelValue: PropTypes.func.isRequired,
  selectedFacets: PropTypes.shape({}),
  urlContext: PropTypes.shape({}),
  zipCode: PropTypes.string.isRequired,
  location: PropTypes.shape({}),
  visitorLocation: VisitorEntities.Location,
  makeModelSubmodelYear: VehicleEntities.MakeModelSubmodelYear,
  isSrpOnMakeType: PropTypes.bool,
  match: PropTypes.shape({
    params: PropTypes.shape({}),
  }),
  isSrpOnUsedCore: PropTypes.bool,
  disableInventoryMLPhotoSort: PropTypes.bool,
  disableGeoLocation: PropTypes.bool,
  seoHeadContent: PropTypes.shape({}),
  isPrequalified: PropTypes.bool,
  isAkamaiCachePrefresh: PropTypes.bool,
  isSearchBot: PropTypes.bool,
  withCoreReviewSection: PropTypes.bool,
  isSHOP1857Enabled: PropTypes.bool,
  isShop2901Enabled: PropTypes.bool,
  renderAsidePageContent: PropTypes.func,
  isVinPreview: PropTypes.bool,
  disableDrivewayNewCarLeadForms: PropTypes.bool,
  isShop3181Chal: PropTypes.bool,
  isSHOP3424Chal: PropTypes.bool,
  semanticSearchSuggestedFilters: PropTypes.shape({
    modifiedZip: PropTypes.string,
  }),
  isShop3165Enabled: PropTypes.bool,
};

UsurpContainerUI.defaultProps = {
  inventoryData: null,
  selectedFacets: {},
  urlContext: {},
  location: {},
  visitorLocation: {},
  makeModelSubmodelYear: null,
  isSrpOnMakeType: false,
  match: {},
  isSrpOnUsedCore: false,
  disableInventoryMLPhotoSort: false,
  disableGeoLocation: false,
  seoHeadContent: {},
  isPrequalified: false,
  isAkamaiCachePrefresh: false,
  isSearchBot: false,
  withCoreReviewSection: false,
  isSHOP1857Enabled: false,
  isShop2901Enabled: false,
  renderAsidePageContent: undefined,
  isVinPreview: false,
  disableDrivewayNewCarLeadForms: false,
  isShop3181Chal: false,
  isSHOP3424Chal: false,
  semanticSearchSuggestedFilters: {},
  isShop3165Enabled: false,
};

const mapStateToProps = state => ({
  isMobile: state.mobile,
  disableInventoryMLPhotoSort: !!get(state, 'featureFlags["disable-inventory-ml-photo-sort"]'),
  disableGeoLocation: !!get(state, 'featureFlags["disable-geolocation"]'),
  isPrequalified: !!get(state, 'financing.prequalificationData'),
  isSHOP1857Enabled: ExperimentUtil.getForcedOrAssignedRecipeName({
    state,
    campaignName: 'shop-1857-model-family',
    defaultVal: 'ctrl',
  }).includes('chal'),
  isShop2901Enabled: ExperimentUtil.getForcedOrAssignedRecipeName({
    state,
    campaignName: 'shop-2901-vdp-tab',
    defaultVal: 'ctrl',
  }).includes('chal'),
  disableDrivewayNewCarLeadForms: !!get(state, 'featureFlags["disableDrivewayNewCarLeadForms"]'),
  isShop3181Chal:
    ExperimentUtil.getForcedOrAssignedRecipeName({
      state,
      campaignName: 'shop-3181-nci-copy',
      defaultVal: 'ctrl',
    }) === 'chal',
  isSHOP3424Chal:
    ExperimentUtil.getForcedOrAssignedRecipeName({
      state,
      campaignName: 'shop-3424-facet-names',
      defaultVal: 'ctrl',
    }) === 'chal',
  isShop3165Enabled: ExperimentUtil.getForcedOrAssignedRecipeName({
    state,
    campaignName: 'shop-3165-recurrent',
    defaultVal: 'ctrl',
  }).includes('chal'),
});

export const stateToPropsConfig = {
  inventoryData: bindToPath('searchResults', InventoryModel, null, false),
  visitorLocation: bindToPath('location', VisitorModel, null, false),
  zipCode: bindToPath('location.zipCode', VisitorModel),
  seoHeadContent: bindToPath('headContent', SEOModel, null, false),
  isSearchBot: bindToPath('isSearchBot', RequestModel),
  isAkamaiCachePrefresh: bindToPath('isAkamaiCachePrefresh', RequestModel),
  withCoreReviewSection: bindToPath(
    ({ makeModelSubmodelYear, urlContext, selectedFacets }) =>
      isUsedSRPReviewComboPage(urlContext, selectedFacets) &&
      !!makeModelSubmodelYear &&
      buildReviewPath(getParamsFromVehicle(makeModelSubmodelYear)),
    EditorialReviewModel,
    editorialReview => !isReviewSectionEmpty(editorialReview),
    false
  ),
  semanticSearchSuggestedFilters: bindToPath(
    ({ searchId }) => (searchId ? `suggestedFilters["${searchId}"]` : null),
    SemanticSearchModel,
    transformSuggestedFilters,
    false
  ),
};

export const UsurpContainer = flow(
  withInventoryListTracking,
  withListTrackingData,
  component => connectToModel(component, stateToPropsConfig),
  component =>
    connectToModel(component, {
      urlContext: bindToPath(URL_CONTEXT_PATH, UrlModel, null, false),
      selectedFacets: bindToPath('searchResultsFilter', InventoryModel, null, false),
      makeModelSubmodelYear: bindToPath(
        ({ match }) =>
          match && match.params && match.params.make && match.params.year ? buildVehiclePath(match.params) : '',
        VehicleModel
      ),
      searchId: bindToPath(() => buildSearchIdPath(), SemanticSearchModel),
    }),
  connect(mapStateToProps)
)(UsurpContainerUI);
