import React, { memo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Zip } from 'site-modules/shared/components/zip/zip';
import { UsurpZipInput } from 'site-modules/shared/components/inventory/usurp-zip-input/usurp-zip-input';

import './zip-code-reminder.scss';

export const ZipCodeReminder = memo(({ className, onZipCodeUpdate, isNational, isMobile, zipCode, isLoading }) => (
  <div
    className={classNames(
      'zip-code-reminder-national-zip bg-blue-90 overlay-white-50 right px-1 text-cool-gray-30 mb-1',
      className
    )}
    style={{ position: isLoading ? 'unset' : 'relative', bottom: 0, left: 0, width: 'auto' }}
  >
    <div className="d-flex align-items-center user-zip-input-container" data-tracking-parent="zip-input-message">
      {isMobile ? (
        <UsurpZipInput
          className="mr-0_75 d-flex mobile-national-zip"
          onZipCodeUpdate={onZipCodeUpdate}
          isNational={isNational}
          isMobile={isMobile}
          dataTrackingParent={''}
        />
      ) : (
        <i className="align-self-center pr-0_5 icon-location text-blue-50" aria-hidden />
      )}
      <div className={classNames({ 'size-14': isMobile })}>
        Showing <span className="fw-bold">Nationwide</span> results. Enter your{' '}
        {isMobile ? (
          'ZIP Code'
        ) : (
          <Zip
            onUpdate={onZipCodeUpdate}
            zip={zipCode}
            buttonText="ZIP Code"
            buttonClassName="national-zip size-16"
            showDropdownIcon={false}
            updateBtnClasses="bg-blue-50"
          />
        )}{' '}
        for local results.
      </div>
    </div>
  </div>
));

ZipCodeReminder.displayName = 'ZipCodeReminder';

ZipCodeReminder.propTypes = {
  onZipCodeUpdate: PropTypes.func,
  className: PropTypes.string,
  isNational: PropTypes.bool,
  isMobile: PropTypes.bool,
  zipCode: PropTypes.string,
  isLoading: PropTypes.bool,
};

ZipCodeReminder.defaultProps = {
  onZipCodeUpdate: null,
  className: null,
  isNational: false,
  isMobile: false,
  zipCode: null,
  isLoading: false,
};
