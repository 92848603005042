import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get } from 'lodash';
import Button from 'reactstrap/lib/Button';
import { connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { useTimeout } from 'site-modules/shared/hooks/use-timeout';
import {
  IncentivesWizardEntities,
  IncentivesWizardModel,
  IncentivesWizardPaths,
} from 'client/data/models/incentives-wizard';
import { SrpTopIncentivesText } from 'site-modules/shared/components/inventory/srp-top-incentives-text/srp-top-incentives-text';
import { getCreativeId } from 'site-modules/shared/components/incentives/incentives-wizard/utils/get-creative-id';
import { wizardTrackingIds } from 'client/tracking/maps/incentives-wizard';
import { IncentivesWizardDrawer } from 'site-modules/shared/components/inventory/incentives-wizard/incentives-wizard-drawer/incentives-wizard-drawer';

import './srp-top-incentives.scss';

function SrpTopIncentivesUI({
  incentives,
  isMobile,
  engineTypes,
  attributes,
  setModelValue,
  defaultYear,
  bodyType,
  pagePublicationState,
}) {
  const [saveSumRebates, setSaveSumRebates] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [setDrawerTimeout] = useTimeout();

  const makeSlug = get(attributes, 'vehicle.make.niceId', '');
  const modelSlug = get(attributes, 'vehicle.model.niceId', '');
  const inventoryCode = get(attributes, 'inventoryType');
  const make = get(attributes, 'vehicle.make.name', '');
  const model = get(attributes, 'vehicle.model.name', '');
  const year = get(attributes, 'vehicle.modelYear.year');

  const onToggle = useCallback(() => {
    setIsOpen(prev => !prev);
  }, []);

  const handleButtonClick = useCallback(() => {
    setDrawerTimeout(async () => {
      await setModelValue(IncentivesWizardPaths.getVehicle(), IncentivesWizardModel, {
        makeSlug,
        modelSlug,
        inventoryCode,
        engineTypes,
        year: year || defaultYear,
      });

      await setModelValue(IncentivesWizardPaths.getIncentivesWizardData(), IncentivesWizardModel, incentives);
      setIsOpen(prev => !prev);
    }, 0);
  }, [defaultYear, engineTypes, incentives, inventoryCode, makeSlug, modelSlug, setDrawerTimeout, setModelValue, year]);
  const creativeId = getCreativeId({ inventoryType: pagePublicationState, incentives });

  return (
    <div
      className={classnames('d-flex justify-content-between align-items-center px-1 bg-white', {
        'flex-column py-1 mb-1_5 mobile': isMobile,
        'mb-1': !isMobile,
      })}
      style={{
        borderRadius: '16px',
        ...(isMobile ? {} : { padding: '24px 16px' }),
      }}
      data-tracking-parent={creativeId}
    >
      <SrpTopIncentivesText incentives={incentives} saveSumRebates={saveSumRebates} className="mr-1" />
      <Button
        size="sm"
        color="blue-50"
        className={classnames('text-capitalize fw-medium size-16 px-1 py-0_5', {
          'mt-1 w-100': isMobile,
        })}
        style={{ borderRadius: '8px' }}
        onClick={handleButtonClick}
        data-tracking-id={wizardTrackingIds.openIncentivesMatcher}
      >
        Check My Eligibility
      </Button>
      <IncentivesWizardDrawer
        onRebatesSaveSum={setSaveSumRebates}
        onToggle={onToggle}
        isOpen={isOpen}
        isMobile={isMobile}
        make={make}
        model={model}
        year={year}
        bodyType={bodyType}
        type={pagePublicationState}
        engineTypes={engineTypes}
        creativeId={creativeId}
      />
    </div>
  );
}

SrpTopIncentivesUI.propTypes = {
  incentives: IncentivesWizardEntities.IncentivesData.isRequired,
  isMobile: PropTypes.bool,
  engineTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  defaultYear: PropTypes.number,
  bodyType: PropTypes.string,
  attributes: PropTypes.shape({
    vehicle: PropTypes.shape({
      make: PropTypes.shape({
        name: PropTypes.string,
        niceId: PropTypes.string,
      }),
      model: PropTypes.shape({
        name: PropTypes.string,
        niceId: PropTypes.string,
      }),
      modelYear: PropTypes.shape({ year: PropTypes.number }),
    }),
    inventoryType: PropTypes.string,
  }),
  setModelValue: PropTypes.func.isRequired,
  pagePublicationState: PropTypes.string.isRequired,
};
SrpTopIncentivesUI.defaultProps = {
  isMobile: false,
  attributes: {},
  defaultYear: 0,
  bodyType: '',
};

export const SrpTopIncentives = connectToModel(SrpTopIncentivesUI, {});
