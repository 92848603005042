import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ContentCollapse } from 'site-modules/shared/components/content-collapse/content-collapse';
import { Link } from 'site-modules/shared/components/link/link';
import { WRAPPER_TYPES } from 'site-modules/shared/components/faq/constants';
import { Faq } from 'site-modules/shared/components/faq/faq';

const SCROLL_PARAMS = { duration: 500, offset: -100 };

/**
 * Renders narrative text block with collapsible content.
 * @param {object} narrativeApiData
 * @param {string} pageName
 * @param {string} pathname
 * @returns {ReactElement}
 */
export function SrpNarrativeText({ narrativeApiData, pageName, pathname, className }) {
  const { items, learnMoreText } = narrativeApiData;

  return (
    <ContentCollapse
      minHeight="60px"
      justify="justify-content-start"
      classes={classnames('srp-narrative-text px-0_5 px-md-0', className)}
      btnContainerClasses="py-0"
      btnClasses="px-0_5 pb-0 text-uppercase"
      resetParams={SCROLL_PARAMS}
      isEclipseFade={false}
      showBtnBelow
      scrollToDefault
    >
      <Faq
        entries={items}
        generateLearnMoreLinks
        learnMoreLinkText={learnMoreText}
        pathname={pathname}
        questionComponent="h2"
        questionClassName="heading-6 text-cool-gray-10"
        answerClassName="size-14 mb-0_5 text-cool-gray-30"
        questionKey="heading"
        answerKey="content"
        title={null}
        wrapperType={WRAPPER_TYPES.none}
      />
      {pageName === 'srp-lease-deals' && (
        <Link to="/cheap-lease-deals/" className="h6 fw-normal">
          Cheap Lease Deals
        </Link>
      )}
    </ContentCollapse>
  );
}

SrpNarrativeText.propTypes = {
  narrativeApiData: PropTypes.shape({
    title: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        heading: PropTypes.string,
        content: PropTypes.arrayOf(PropTypes.string),
      })
    ),
    learnMoreText: PropTypes.string,
  }).isRequired,
  pageName: PropTypes.string,
  pathname: PropTypes.string,
  className: PropTypes.string,
};

SrpNarrativeText.defaultProps = {
  pageName: '',
  pathname: '',
  className: '',
};
