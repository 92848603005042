import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { isFinite } from 'lodash';
import { getPotentialRebatesSum } from 'site-modules/shared/components/incentives/incentives-wizard/utils/get-potential-rebates-sum';
import { InventoryEvInsightsEntities } from 'client/data/models/inventory-ev-insights';
import { formatPriceString, getPriceString } from 'site-modules/shared/utils/price-utils';

import './srp-top-incentives-text.scss';

export function SrpTopIncentivesText({ saveSumRebates, incentives, className, textColorClassName }) {
  const totalRebates = getPotentialRebatesSum(incentives);
  const classes = classnames('mb-0 fw-bold', textColorClassName, className);
  const headingStyle = { lineHeight: '1.5rem', fontSize: '1.25rem' };

  if (isFinite(saveSumRebates)) {
    return (
      <h2 className={classes} style={headingStyle}>
        Save <span className="total-rebates-value">{formatPriceString(saveSumRebates)} </span> with EV Tax Credits &
        Rebates
      </h2>
    );
  }

  return (
    <h2 className={classes} style={headingStyle}>
      Save{' '}
      {!!totalRebates && (
        <>
          up to <span className="total-rebates-value">{getPriceString(totalRebates)} </span>
        </>
      )}
      with EV Tax Credits & Rebates
    </h2>
  );
}

SrpTopIncentivesText.propTypes = {
  incentives: InventoryEvInsightsEntities.EvIncentivesData.isRequired,
  saveSumRebates: PropTypes.number,
  className: PropTypes.string,
  textColorClassName: PropTypes.string,
};
SrpTopIncentivesText.defaultProps = {
  saveSumRebates: null,
  className: null,
  textColorClassName: 'text-cool-gray-10',
};
